








































import Vue from "vue";

export default Vue.extend({
  name: "Event",
  props: {
    link: String,
    image: String,
    title: String,
    date: [Date, String],
    who: String,
    where: String,
    what: String,
    description: String,
  },
});























































import Vue from "vue";
import Event from "@/components/Event.vue";
import { Events } from "@/constants";

export default Vue.extend({
  name: "Events",

  components: { Event },
  data: () => {
    const pastEvents = Events.events
      .filter((e) => e.date < new Date())
      .sort((a, b) => (a.date < b.date ? 1 : -1));
    const upcomingEvents = Events.events
      .filter((e) => e.date === "TBD" || e.date > new Date())
      .sort((a, b) => (a.date > b.date || b.date !== "TBD" ? 1 : -1));

    return {
      pastEvents,
      upcomingEvents,
    };
  },
});
